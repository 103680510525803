
import { v4 as uuid } from 'uuid'

export default {
    setup() {
        return {
            uuid
        }
    }
}
